<template>
  <div>
    <div v-if="items.length">
      <v-row>
        <v-col sm="6" md="4" lg="3" xl="2" cols="12" v-for="(parcel, index) in items" :key="index">
          <parcel-detail
            @update="$emit('update')"
            v-if="parcel"
            :data="parcel"
            @deleted="$emit('deleted')"
          ></parcel-detail>
        </v-col>
      </v-row>
      <!-- <div class="text-center mt-3" v-if="parcels.length && page < pageInfo.totalPages">
        <v-btn large outlined color="secondary" @click="loadMore()"
          >Load More <v-icon>mdi-chevron-down</v-icon></v-btn
        >
      </div> -->
    </div>
    <v-row v-else-if="loading">
      <v-col v-for="(i, index) in 4" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-fade-transition>
      <div v-if="$store.getters.parcels.length && !items.length" class="">
        <div class="d-flex justify-center">
          <PeepsAlertAnimation :value="true" color="peeps">
            No parcels were found matching your filter
          </PeepsAlertAnimation>
        </div>
        <div class="d-flex justify-center">
          <v-btn color="info" class="v-btn--active" text @click="$store.dispatch('resetMapConfig')">
            <span class="text-capitalize">Clear Filters</span>
            <v-icon>mdi-filter-remove-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import ParcelDetail from './Detail.vue';
import { mapGetters } from 'vuex';
import { calcPagination, paginate } from '../../utils/parcel';
import Parcel from '../../services/parcel';
import parcelsComputedMixin from '../../mixins/parcelsComputed';
export default {
  mixins: [parcelsComputedMixin],
  components: {
    ParcelDetail,
    PeepsAlertAnimation: () => import('../../components/Core/Alerts/PeepsAlertRight.vue')
  },
  data: () => ({
    page: 1,
    pageSize: 10,
    pageInfo: {},
    loadDebounce: null,
    pageLoad: {},
    dialogs: {
      detail: false
    },
    highlight: ''
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  computed: {
    ...mapGetters({
      parcels: 'parcels'
    }),
    detail() {
      return this.$store.state.parcel.detail;
    },
    filters() {
      return this.$store.state.parcel.mapConfig;
    },
    items() {
      const res = paginate(
        this.parcelsComputed,
        this.page,
        this.pageSize,
        'number',
        undefined,
        true
      );
      return res;
    },
    loading: {
      get() {
        return this.$store.getters.parcelLoading;
      },
      set(val) {
        this.$store.commit('parcelLoading', val);
      }
    }
  },
  watch: {
    parcels: {
      handler: 'updatePageInfo',
      immediate: true
    }
  },
  methods: {
    onScroll() {
      if (window.pageYOffset + window.innerHeight >= document.documentElement.scrollHeight - 200) {
        if (this.page < this.pageInfo.totalPages) {
          if (this.loadDebounce) {
            return;
          }
          this.loadDebounce = setTimeout(() => {
            this.page += 1;
            clearTimeout(this.loadDebounce);
            this.loadDebounce = null;
          }, 100);
        }
      }
    },
    updatePageInfo() {
      this.pageInfo = calcPagination(this.page, this.pageSize, this.parcels.length);
      this.page = this.pageInfo.page;
    },
    loadMore() {
      this.page += 1;
      this.updatePageInfo();
    }
  }
};
</script>

<style></style>
